import dayjs from 'dayjs'
import { ParameterChangeProposalFormState } from 'js/models/Governance'

export const timeNow = dayjs().toISOString()

export const CreateTokenProposalInitialFormState = {
  title: '',
  summary: '',
  metadata: '',
  blockchain: '',
  name: '',
  symbol: '',
  decimals: '',
  chainId: '',
  bridgeId: '',
  lockproxyHash: '',
  tokenAddress: '',
}

export const CreateOracleProposalInitialFormState = {
  title: '',
  summary: '',
  metadata: '',
  oracleId: '',
  oracleDescription: '',
  minTurnoutPercentage: '',
  maxResultAge: '',
  securityType: 'SecuredByValidators',
  resultStrategy: '',
  resolution: '',
  spec: '',
}

export const CreateMarketProposalInitialFormState = {
  title: '',
  summary: '',
  metadata: '',
  name: '',
  displayName: '',
  marketType: 'spot',
  marketDescription: '',
  base: '',
  quote: '',
  lotSize: '',
  tickSize: '',
  minQuantity: '',

  riskStepSize: '0',
  initialMarginBase: '1.0',
  initialMarginStep: '0.000000000000000000',
  maintenanceMarginRatio: '0.000000000000000000',
  maxLiquidationOrderTicket: '0',
  maxLiquidationOrderDuration: '0',
  impactSize: '0',
  markPriceBand: '0',
  lastPriceProtectedBand: '0',
  indexOracleId: '',
  expiryTime: timeNow,
  tradingBandwidth: '0',
}

export const UpdateMarketProposalInitialFormState = {
  title: '',
  summary: '',
  metadata: '',
  name: '',
  displayName: '',
  marketType: 'spot',
  marketDescription: '',
  minQuantity: '',
  lotSize: '',
  tickSize: '',
  takerFee: '',
  makerFee: '',

  riskStepSize: '0',
  initialMarginBase: '1.0',
  initialMarginStep: '0.000000000000000000',
  maintenanceMarginRatio: '0.000000000000000000',
  maxLiquidationOrderTicket: '0',
  maxLiquidationOrderDuration: '0',
  impactSize: '0',
  markPriceBand: '0',
  lastPriceProtectedBand: '0',
  tradingBandwidth: '0',
}

export const LinkPoolProposalInitialFormState = {
  title: '',
  summary: '',
  metadata: '',
  poolID: '',
  marketName: '',
}

export const UnlinkPoolProposalInitialFormState = {
  title: '',
  summary: '',
  metadata: '',
  poolID: '',
}

export const SetPoolRewardWeightsProposalInitialFormState = {
  title: '',
  summary: '',
  metadata: '',
  weights: [{
    poolID: '',
    weight: '',
  }],
}

export const SetRewardCurveProposalInitialFormState = {
  title: '',
  summary: '',
  metadata: '',
  startTime: timeNow,
  initialRewardBps: '',
  reductionMultiplierBps: '',
  reductionIntervalSeconds: '',
  reductions: '',
  finalRewardBps: '',
}

export const SetCommitmentCurveProposalInitialFormState = {
  title: '',
  summary: '',
  metadata: '',
  maxDuration: '',
  maxRewardMultiplier: '',
}

export const SetSettlementPriceProposalInitialFormState = {
  title: '',
  summary: '',
  metadata: '',
  market: '',
  settlementPrice: '',
}

export const CommunityPoolSpendProposalInitialFormState = {
  title: '',
  summary: '',
  metadata: '',
  denom: '',
  amount: '',
  recipient: '',
}

export const ParameterChangeProposalInitialFormState: ParameterChangeProposalFormState = {
  title: '',
  summary: '',
  metadata: '',
  parameters: [],
}

export const SoftwareUpgradeProposalInitialFormState = {
  title: '',
  summary: '',
  metadata: '',
  name: '',
  time: '',
  height: '',
  info: '',
}

export const TextProposalInitialFormState = {
  title: '',
  summary: '',
  metadata: '',
}

export const UpdatePoolProposalInitialFormState = {
  title: '',
  summary: '',
  metadata: '',
  poolID: '',
  swapFee: '',
  numQuotes: '',
}


export const SetMsgFeeProposalInitialFormState = {
  title: '',
  summary: '',
  metadata: '',
  msgType: '',
  gasCost: '',
}