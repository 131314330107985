import { SetSettlementPriceProposalFormState } from "js/models"
import store from "js/store"
import { escapeHtmlGo } from "js/utils"
import { fieldTypes } from "../Helpers/types"
import { DecCoin } from 'carbon-js-sdk/lib/codec/cosmos/base/v1beta1/coin'
import { CarbonTx } from "carbon-js-sdk"
import { MsgSubmitProposal } from "carbon-js-sdk/lib/codec/cosmos/gov/v1/tx"
import { MsgUpdateSettlementPrice } from "carbon-js-sdk/lib/codec/Switcheo/carbon/pricing/tx"

export const settlementPriceFormRequiredFields = ['market', 'settlementPrice']

export function settlementPriceConfig() {
  const markets = store.getState().app.markets
  return {
    market: {
      id: 'market',
      label: 'Market',
      type: fieldTypes.select,
      options: Object.values(markets).map((market: any) => market?.name),
      placeholder: 'Select a market',
    },
    settlementPrice: {
      id: 'settlementPrice',
      label: 'Settlement Price',
      tooltip: 'Settlement price for the expired futures market',
      type: fieldTypes.number,
      constraints: {
        greaterThanOrEqualTo: 0,
      },
    },
  }
}

export function setSettlementPriceProposalValue(proposer: string | undefined, initialDeposit: DecCoin[], authority: string): MsgSubmitProposal {
  const formState = store.getState().governance.submitProposalFormState as SetSettlementPriceProposalFormState

  const { title, summary, metadata, market, settlementPrice } = formState!

  const msg = MsgUpdateSettlementPrice.fromPartial({
    authority: authority,
    marketId: market,
    settlementPrice,
  })

  const value = {
    title: escapeHtmlGo(title),
    summary: escapeHtmlGo(summary),
    metadata: escapeHtmlGo(metadata),
    expedited: false,
    initialDeposit,
    proposer: proposer ?? "",
    messages: [
      {
        typeUrl: CarbonTx.Types.MsgUpdateSettlementPrice,
        value: MsgUpdateSettlementPrice.encode(msg).finish()
      }
    ],
  }

  return value
}
