import { makeStyles, Theme } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import { AssetParams } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/cdp/asset_params'
import { Params } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/cdp/params'
import { RateStrategyParams } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/cdp/rate_strategy_params'
import { Coin } from 'carbon-js-sdk/lib/codec/cosmos/base/v1beta1/coin'
import { SWTHAddress } from 'carbon-js-sdk/lib/util/address'
import CryptoJS from 'crypto-js'
import { TableSection } from 'js/components'
import Page from 'js/components/Page'
import { TaskName, TaskNames } from 'js/constants'
import { useAsyncTask, useRedux } from 'js/hooks'
import { bnOrZero, SHIFT_DECIMALS } from 'js/utils'
import React, { ReactElement, useEffect, useState } from 'react'
import { TokenInfoRow } from './components'
import { Assets } from './helper'
import { InfoHeaders, InfoMintHeaders } from './tokenListConfig'

interface CDPParamsWithStablecoinInterest extends Params {
  stablecoinInterestRate: string | number | BigNumber
}

interface Props { }

const CDPTokenList: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const classes = useStyles()
  const sdk = useRedux((state) => state.core.carbonSDK)
  const tokens = useRedux((state) => state.app.tokensMap)
  const [loadCdpAssets] = useAsyncTask(TaskName.LoadCdpAsset)
  const [allAssetsInfo, setAllAssetsInfo] = useState<Assets[]>([]);
  const [allMintableAssetsInfo, setMintableAssetsInfo] = useState<any[]>([]);
  const [loadRateStrategies] = useAsyncTask(TaskName.LoadRateStrategies)
  const [cdpModBalances, setcdpModBalances] = useState<Coin[]>([]);
  const [loadCdpModBalances] = useAsyncTask(TaskName.LoadCdpModBalances)
  const [allRateStrategies, setAllRateStrategies] = useState<RateStrategyParams[]>([]);
  const [loadCdpParams] = useAsyncTask(TaskName.LoadCdpParams)
  const [loadingAssets, setLoadingAssets] = useState<boolean>(true)
  const [cdpParams, setCdpParams] = useState<CDPParamsWithStablecoinInterest>({
    interestFee: '',
    liquidationFee: '',
    stablecoinInterestRate: '',
    completeLiquidationThreshold: '',
    minimumCloseFactor: '',
    smallLiquidationSize: '',
    stablecoinMintCap: '',
    cdpPaused: false,
    stalePriceGracePeriod: '' as any,
    stablecoinInterestRateAdjusterCoefficient: '',
  });

  useEffect(() => {
    if (!sdk) return
    loadRateStrategies(async () => {
      const strategies = await sdk.query.cdp.RateStrategyAll({})
      setAllRateStrategies(strategies.rateStrategyParamsAll)
    })
    loadCdpParams(async () => {
      const params = await (await sdk.query.cdp.Params({})).params as CDPParamsWithStablecoinInterest
      setCdpParams(params ?? {
        interestFee: '',
        liquidationFee: '',
        stablecoinInterestRate: '',
        completeLiquidationThreshold: '',
        minimumCloseFactor: '',
        smallLiquidationSize: '',
        stablecoinMintCap: '',
        cdpPaused: false,
        stalePriceGracePeriod: '',
        stablecoinInterestRateAdjusterCoefficient: '',
      })
    })
    loadCdpModBalances(async () => {
      const cdpAddressHash = CryptoJS.SHA256(`cdp`).toString(CryptoJS.enc.Hex);
      const cdpAddress = SWTHAddress.encode(cdpAddressHash, { network: sdk.network });
      const balances = await sdk.query.bank.AllBalances({ address: cdpAddress, resolveDenom: false })
      setcdpModBalances(balances.balances)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk])

  useEffect(() => {
    if (!tokens || !sdk || !allRateStrategies.length || !cdpParams.interestFee || !cdpModBalances) return
    loadCdpAssets(async () => {
      const allAssets = await sdk.query.cdp.AssetAll({})
      const mintableAssets = await sdk.query.cdp.StablecoinDebt({})

      if (mintableAssets?.stablecoinDebtInfo?.denom) {
        const mintTokenInfo = tokens[mintableAssets?.stablecoinDebtInfo?.denom] ?? Object.values(tokens).find((tk) => tk.denom === mintableAssets?.stablecoinDebtInfo?.denom)
        const totalMint = await sdk.query.bank.SupplyOf({ denom: mintableAssets?.stablecoinDebtInfo?.denom })
        const totalMintAmount = (bnOrZero(totalMint.amount?.amount)).toNumber()
        // mint assets
        const mintAsset = [{
          ...mintableAssets?.stablecoinDebtInfo,
          lendApr: 3,
          borrowApr: bnOrZero(cdpParams?.stablecoinInterestRate).shiftedBy(-2).toNumber(),
          totalAmount: totalMintAmount,
          availableBorrow: Infinity,
          decimals: mintTokenInfo?.decimals ?? 6
        }]
        setMintableAssetsInfo(mintAsset)
      }

      // normal assets
      const debtInfo = await sdk.query.cdp.TokenDebtAll({})
      if (!debtInfo?.debtInfosAll?.length) return
      const formattedAssets: Assets[] = await Promise.all(allAssets?.assetParamsAll?.map(async (asset: AssetParams) => {
        const { denom, oracleId, rateStrategyName, loanToValue,
          liquidationThreshold, liquidationDiscount,
          supplyCap, borrowCap } = asset
        const assetDebtInfo = debtInfo.debtInfosAll.find((o) => o.denom === denom)
        const { totalPrincipal: totalBorrowed, utilizationRate } = assetDebtInfo!
        const balanceInMod = cdpModBalances.find((o) => o.denom === denom)
        const totalAmount = bnOrZero(totalBorrowed).plus(bnOrZero(balanceInMod?.amount)).toNumber()
        const tokenInfo = tokens[denom] ?? Object.values(tokens).find((tk) => tk.denom === denom)
        const availableBorrow = Number(borrowCap ?? 0) > 0
          ? BigNumber.min(bnOrZero(borrowCap).minus(bnOrZero(totalBorrowed)), bnOrZero(balanceInMod?.amount)).shiftedBy(-(tokenInfo?.decimals.toNumber())).toNumber()
          : bnOrZero(balanceInMod?.amount).shiftedBy(-(tokenInfo?.decimals.toNumber())).toNumber()
        const formattedAsset: any = {
          denom,
          oracleId,
          rateStrategyName,
          loanToValue,
          liquidationThreshold,
          liquidationDiscount,
          supplyCap,
          borrowCap,
          totalBorrowed,
          totalAmount,
          utilizationRate: bnOrZero(utilizationRate).shiftedBy(-SHIFT_DECIMALS + 4).toNumber(),
          tokenAddress: tokenInfo?.tokenAddress ?? '',
          decimals: tokenInfo?.decimals.toNumber() ?? 0,
          availableBorrow,
          symbol: tokenInfo?.symbol
        }

        // calculate interest
        const rateStrategy = allRateStrategies.find((r) => r.name === asset.rateStrategyName)
        const borrowApr = await sdk.cdp.calculateAPY(asset.denom, assetDebtInfo, asset, rateStrategy) as BigNumber
        const lendApr = await sdk.cdp.calculateLendAPY(asset.denom, borrowApr, assetDebtInfo, cdpParams)
        formattedAsset.borrowApr = borrowApr.shiftedBy(2).toFixed(2)
        formattedAsset.lendApr = lendApr.shiftedBy(2).toFixed(2)
        return formattedAsset
      })) ?? []
      setAllAssetsInfo(formattedAssets)
      setLoadingAssets(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokens, sdk, allRateStrategies, cdpParams.interestFee, cdpModBalances])
  return (
    <Page title="Borrowable Assets">
      <TableSection
        cellClass={classes.cellClass}
        headerCells={InfoHeaders}
        loadName={TaskNames.Cdp.assets}
        models={allAssetsInfo}
        rowComponent={TokenInfoRow}
        title="Assets"
        itemName="assets"
        pagination
        searchBar
        searchKeys={["denom", "symbol"]}
        placeholder="Search for token (eg. SWTH)"
        loading={loadingAssets}
      />
      <TableSection
        cellClass={classes.cellClass}
        headerCells={InfoMintHeaders}
        loadName={TaskNames.Cdp.assets}
        models={allMintableAssetsInfo}
        rowComponent={TokenInfoRow}
        title="Mintable Assets"
        itemName="assets"
        pagination
        searchBar
        searchKeys={["denom", "symbol"]}
        placeholder="Search for token (eg. SWTH)"
        loading={loadingAssets}
      />
    </Page>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  cellClass: {
    padding: '0.5rem 1.15rem 0.5rem 0.7rem',
    whiteSpace: 'nowrap',
  },
}))

export default CDPTokenList
