import { Divider, List, ListItem, makeStyles, Theme } from '@material-ui/core'
import { Network } from 'carbon-js-sdk/lib/constant'
import clsx from 'clsx'
import { JSONBlock } from 'js/components'
import { switcheo } from 'js/theme/palettes/colors'
import React, { ReactElement } from 'react'

interface Props {
  logs: object
  msgLog: any
  network: Network
}

const LogMessage: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { logs, msgLog } = props
  const { typeUrl, value } = msgLog
  const classes = useStyles()

  //final parseing
  if (typeUrl === '/Switcheo.carbon.oracle.MsgCreateResult' && !!value?.validatorSignatures?.length) {
    value?.validatorSignatures?.forEach((o: any, index: number) => {
      const uint8array = value.validatorSignatures[index].pubKey
      value.validatorSignatures[index].pubKey = Buffer.from(uint8array).toString('base64')
    })
  }
  return (
    <List className={classes.list}>
      <Divider className={classes.divider} />
      <ListItem className={clsx(classes.bottom, classes.type)}>
        <JSONBlock header='Type' content={{ typeUrl }} />
      </ListItem>
      <ListItem className={clsx(classes.bottom, classes.type)}>
        <JSONBlock header='Message' content={value} />
      </ListItem>
      <ListItem className={classes.type}>
        <JSONBlock header='Logs' content={logs} />
      </ListItem>
    </List>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    height: '3px',
    backgroundColor: switcheo.primary[800],
    marginBottom: '1.5em',
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(1, 0, 3),
    padding: 0,
  },
  type: {
    fontWeight: 'bold',
    paddingTop: '0.625rem',
    paddingBottom: '0.625rem',
    alignItems: 'flex-start',
  },
  bottom: {
    paddingBottom: "10px",
    backgroundImage: "linear-gradient(#ccc,#ccc)",
    backgroundSize: '98% 2px',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    // borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
  },
}))

export default LogMessage
