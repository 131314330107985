import { CarbonSDK, CarbonTx, GovUtils } from "carbon-js-sdk";
import { Proposal } from "carbon-js-sdk/lib/codec/cosmos/gov/v1/gov";
import { ProposalTypes } from "carbon-js-sdk/lib/util/gov";
import { SHIFT_DECIMALS, bnOrZero, makeMoneyLabel } from "js/utils";
import { startCase } from "lodash";
import React from "react";
import { CellLink } from "js/components";
import { Paths } from "js/constants";
import { TxTypes } from 'carbon-js-sdk/lib/codec'
import BigNumber from "bignumber.js";

export interface ProposalDescriptionEntry {
  header: string | ParamsHeader
  cell: string | React.ReactElement | object | ParamsCell
}

// Used to display parameter update proposals
export type ParamsHeader = {
  type: string
  authority: string
}
export type ParamsCell = {
  header: string
  cell: any
}

export const isParamsHeader = (header: any): header is ParamsHeader => {
  return header.subHeader !== undefined && header.value !== undefined
}

export const isParamsCell = (cell: any): cell is ParamsCell => {
  return cell.header !== undefined && cell.cell !== undefined
}

export const getVoteOption = (voteOption: string) => {
  if (voteOption === "VOTE_OPTION_YES") return "Yes"
  if (voteOption === "VOTE_OPTION_NO") return "No"
  if (voteOption === "VOTE_OPTION_ABSTAIN") return "Abstain"
  if (voteOption === "VOTE_OPTION_NO_WITH_VETO") return "No With Veto"
  return ""
}

export const getProposalContent = (content: any) => {
  let result = GovUtils.decodeContent(content)
  if (!result?.typeUrl) return content
  return result
}

export const getProposalDisplayData = (proposal?: Proposal, sdk?: CarbonSDK) => {
  if (!proposal) {
    return {
      topic: "",
      title: "",
      description: "",
      extraInfoJson: undefined,
      proposalDescriptionList: [],
    }
  }

  const content = proposal.messages[0]

  // handle proposals with no messages
  if(!content) {
    return {
      topic: "",
      title: proposal.title,
      description: proposal.summary.replace('\\n\\n', '\n\n'),
      extraInfoJson: undefined,
      proposalDescriptionList: [],
    }
  }

  // handle legacy gov prop
  if (content.typeUrl === CarbonTx.Types.MsgGovExecLegacyContent) {
    const { typeUrl, value } = GovUtils.decodeContent(proposal.messages[0])
    const { title, topic, description } = value
    let proposalDescriptionList: ProposalDescriptionEntry[] = []
    proposalDescriptionList.push({ header: 'Proposal Type', cell: typeUrl })
    try {
      proposalDescriptionList.push(...getProposalDescriptionList(typeUrl, value, sdk))
    } catch (error) {
      proposalDescriptionList.push({
        header: "Error",
        cell: `Parse proposal error: ${error}`,
      })
    }
    const extraInfoJson = getExtraInfo(typeUrl, value)

    return {
      topic,
      title,
      description: description.replace('\\n\\n', '\n\n'),
      extraInfoJson,
      proposalDescriptionList
    }
  }

  // handle new gov proposals
  const { title, summary } = proposal
  let extraInfoJson
  let proposalDescriptionList: ProposalDescriptionEntry[] = []
  proposal.messages.forEach((msg) => {
    const { typeUrl, value } = GovUtils.decodeContent(msg)
    try {
      // currently used for paramater update
      // if value contains params, create custom headers and cells
      if (value.params) {
        value.params = transformValuesToString(value.params)
        const cell = Object.assign({}, ...getProposalDescriptionList(typeUrl, value.params, sdk))
        proposalDescriptionList.push(
          {
            header: { type: typeUrl, authority: value.authority },
            cell
          }
        )
      } else {
        proposalDescriptionList.push({ header: 'Proposal Type', cell: typeUrl })
        proposalDescriptionList.push(...getProposalDescriptionList(typeUrl, value, sdk))
      }
    } catch (error) {
      proposalDescriptionList.push({
        header: "Error",
        cell: `Parse proposal error: ${error}`,
      })
    }

    extraInfoJson = getExtraInfo(typeUrl, value)
  })

  return {
    title,
    description: summary.replace('\\n\\n', '\n\n'),
    extraInfoJson,
    proposalDescriptionList
  }
}

const transformValuesToString = (obj: any) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }
  if (typeof obj === 'object') {
    Object.entries(obj).forEach(([key, value]: [string, any]) => {
      if (value instanceof Uint8Array) {
        obj[key] = decodeUint8Array(value)
        const bn = new BigNumber(obj[key])
        if (bn.isFinite()) {
          obj[key] = bn.shiftedBy(-SHIFT_DECIMALS).toString()
        }
      }

      if (typeof value === 'object') {
        transformValuesToString(value)
      }
    })
  }

  return obj
}

const decodeUint8Array = (value: any) => {
  const uint8Array = new Uint8Array(value)
  const decoder = new TextDecoder()
  return decoder.decode(uint8Array)
}


const getExtraInfo = (typeUrl: string, value: any) => {
  let extraInfoJson;
  switch (typeUrl) {
    case ProposalTypes.SoftwareUpgradeV2:
    case ProposalTypes.SoftwareUpgrade: {
      try {
        extraInfoJson = JSON.parse(value.plan?.info);
      } catch (error) { };
      break;
    }
    default:
      break;
  }
  return extraInfoJson
}

const getProposalDescriptionList = (typeUrl: string, value: any, sdk?: CarbonSDK) => {
  let proposalDescriptionList: ProposalDescriptionEntry[] = []
  switch (typeUrl) {
    case ProposalTypes.ParameterChange: {
      for (const changes of value.changes) {
        proposalDescriptionList.push(
          { header: 'Topic', cell: changes?.subspace },
          { header: 'Parameter', cell: changes?.key },
          { header: 'Value', cell: changes?.value },
        )
      }
      break;
    }

    case ProposalTypes.SoftwareUpgradeV2:
    case ProposalTypes.SoftwareUpgrade: {
      proposalDescriptionList.push(
        { header: 'Name', cell: value.plan?.name },
        {
          header: 'Upgrade Height',
          cell: React.createElement(
            CellLink,
            { to: Paths.Block.replace(":height", value.plan?.height.toNumber()) },
            value.plan?.height.toNumber()
          )
        }
      )
      break;
    }

    case ProposalTypes.CommunityPoolSpendV2:
    case ProposalTypes.CommunityPoolSpend: {
      const amount = value.amount[0]?.amount
      const denom = value.amount[0]?.denom
      const decimals = sdk?.token.getDecimals(denom)
      const formattedAmount = makeMoneyLabel(bnOrZero(amount), {
        decimals,
      })
      proposalDescriptionList.push(
        { header: 'Recipient', cell: value.recipient },
        { header: 'Amount', cell: `${formattedAmount} ${denom}` },
      )
      break;
    }

    case ProposalTypes.SetRewardsWeightsV2: {
      const params = value.setRewardsWeightsParams.weights
      Object.entries(params).forEach(([key, value]: [string, any]) => {
        proposalDescriptionList.push({ header: startCase(key), cell: value })
      })
      break;
    }
    case ProposalTypes.SetRewardsWeights: {
      const params = value.msg.weights;
      Object.entries(params).forEach(([key, value]: [string, any]) => {
        proposalDescriptionList.push({ header: startCase(key), cell: value })
      })
      break;
    }

    case ProposalTypes.UpdateMarketV2: // TODO add parsing of proposal description for update market
    case GovUtils.ProposalTypes.UpdateMarket: {
      const params = value;
      Object.entries(params).forEach(([key, value]: [string, any]) => {
        let finalValue = value
        switch (key) {
          case "tickSize":  
            finalValue = bnOrZero(value).shiftedBy(-SHIFT_DECIMALS).toString()
            break;
          case "takerFee":
            finalValue = bnOrZero(value).shiftedBy(-SHIFT_DECIMALS).toString()
            break;
          case "makerFee":
            finalValue = bnOrZero(value).shiftedBy(-SHIFT_DECIMALS).toString()
            break;
          case "initialMarginBase":
            finalValue = bnOrZero(value).shiftedBy(-SHIFT_DECIMALS).toString()
            break;
          case "initialMarginStep":
            finalValue = bnOrZero(value).shiftedBy(-SHIFT_DECIMALS).toString()
            break;
          case "maintenanceMarginRatio":
            finalValue = bnOrZero(value).shiftedBy(-SHIFT_DECIMALS).toString()
            break;
          default:
            finalValue = value
            break;
        }
        proposalDescriptionList.push({ header: startCase(key), cell: finalValue })
      })
      break;
    }

    case ProposalTypes.CreateAllianceV2:
    case GovUtils.ProposalTypes.CreateAlliance: {
      const denom = value.denom
      const rewardWeight = bnOrZero(value.rewardWeight).shiftedBy(-SHIFT_DECIMALS).toString()
      const takeRate = bnOrZero(value.takeRate).shiftedBy(-SHIFT_DECIMALS).toString()
      const rewardChangeRate = bnOrZero(value.rewardChangeRate).shiftedBy(-SHIFT_DECIMALS).toString()
      const rewardChangeInterval = `${value.rewardChangeInterval.seconds.toNumber()}s`
      const rewardWeightRange = `${bnOrZero(value.rewardWeightRange.min).shiftedBy(-SHIFT_DECIMALS).toString()} - ${bnOrZero(value.rewardWeightRange.max).shiftedBy(-SHIFT_DECIMALS).toString()}`
      proposalDescriptionList.push(
        { header: "Denom", cell: denom },
        { header: "Reward Weight", cell: rewardWeight },
        { header: "Take Rate", cell: takeRate },
        { header: "Reward Change Rate", cell: rewardChangeRate },
        { header: "Reward Change Interval", cell: rewardChangeInterval },
        { header: "Reward Weight Range", cell: rewardWeightRange }
      )
      break;
    }

    case ProposalTypes.UpdateAllianceV2:
    case GovUtils.ProposalTypes.UpdateAlliance: {
      const denom = value.denom
      const rewardWeight = bnOrZero(value.rewardWeight).shiftedBy(-SHIFT_DECIMALS).toString()
      const takeRate = bnOrZero(value.takeRate).shiftedBy(-SHIFT_DECIMALS).toString()
      const rewardChangeRate = bnOrZero(value.rewardChangeRate).shiftedBy(-SHIFT_DECIMALS).toString()
      const rewardChangeInterval = `${value.rewardChangeInterval.seconds.toNumber()}s`
      proposalDescriptionList.push(
        { header: "Denom", cell: denom },
        { header: "Reward Weight", cell: rewardWeight },
        { header: "Take Rate", cell: takeRate },
        { header: "Reward Change Rate", cell: rewardChangeRate },
        { header: "Reward Change Interval", cell: rewardChangeInterval },
      )
      break;
    }

    case ProposalTypes.DeleteAllianceV2:
    case GovUtils.ProposalTypes.DeleteAlliance: {
      const denom = value.denom
      proposalDescriptionList.push(
        { header: "Denom", cell: denom },
      )
      break;
    }

    case ProposalTypes.SetRewardCurveV2: {
      const params = value.setRewardCurveParams;
      Object.entries(params).forEach(([key, value]: [string, any]) => {
        const stringValue = value?.toString?.();
        const cellValue = stringValue === "[object Object]" ? value : stringValue;
        proposalDescriptionList.push({ header: startCase(key), cell: cellValue })
      })
      break;
    }

    case ProposalTypes.CancelSoftwareUpgradeV2:
    case ProposalTypes.SetCommitmentCurveV2:
    case ProposalTypes.SetMsgGasCostV2: {
      const params = value;
      Object.entries(params).forEach(([key, value]: [string, any]) => {
        const stringValue = value?.toString?.();
        const cellValue = stringValue === "[object Object]" ? value : stringValue;
        proposalDescriptionList.push({ header: startCase(key), cell: cellValue })
      })
      break;
    }

    // Parameter update proposals
    case TxTypes.MsgAuthUpdateParams:
    case TxTypes.MsgBankUpdateParams:
    case TxTypes.MsgGovUpdateParams:
    case TxTypes.MsgDistributionUpdateParams:
    case TxTypes.MsgSlashingUpdateParams:
    case TxTypes.MsgStakingUpdateParams:
    case TxTypes.MsgOracleUpdateParams: {
      let cell: {
        [key: string]: any
      } = {}
      Object.entries(value).forEach(([key, value]: [string, any]) => {
        const stringValue = value?.toString?.();
        const cellValue = stringValue === "[object Object]" ? value : stringValue;
        cell[startCase(key)] = cellValue
      })
      proposalDescriptionList.push({ header: "Params", cell: cell })
      break;
    }

    case TxTypes.MsgCreateOracle: {
      const params = value;
      Object.entries(params).forEach(([key, value]: [string, any]) => {
        const stringValue = value?.toString?.();
        const cellValue = stringValue === "[object Object]" ? value : stringValue;
        proposalDescriptionList.push({ header: startCase(key), cell: cellValue })
      })
      break;
    }

    default: {
      try {
        const params = value.msg;
        Object.entries(params).forEach(([key, value]: [string, any]) => {
          const stringValue = value?.toString?.();
          const cellValue = stringValue === "[object Object]" ? value : stringValue;
          proposalDescriptionList.push(cellValue)
        })
      } catch (error) { };
    }
  }
  return proposalDescriptionList
}