import { IndexedTx } from '@cosmjs/stargate'
import { BlockResponse, BlockResultsResponse } from '@cosmjs/tendermint-rpc/build/tendermint37'
import { GenericUtils } from 'carbon-js-sdk'
import { TaskNames } from 'js/constants'
import { SagaIterator } from 'redux-saga'
import { call, Effect, put, spawn, takeLatest } from 'redux-saga/effects'
import { AppActionType } from '../actions/app'
import { clear, setBlockInfo, setEvents, setTransactions } from '../actions/block'
import { runSagaTask, waitforSDK } from './helper'
import Saga from './Saga'

export interface BlockResultsAPIResponse {
  height: number,
  txs_results: any,
  begin_block?: any,
  end_block?: any,
  finalize_block_events?: any,
  validator_updates: any,
  consensus_param_updates: any,
  app_hash: any
}

const getBlockResult = async (height: number | string, network: string) => {
  const networkAppend = network === 'mainnet' ? '' : 'devnet' ? 'dev-' : 'test-'
  const blockResult = network === 'mainnet'
    ? await (await fetch(`https://${networkAppend}tm-api-archive.carbon.network/block_results?height=${height}`)).json() :
    await (await fetch(`https://${networkAppend}tm-api.carbon.network/block_results?height=${height}`)).json()
  return (blockResult?.result ?? {}) as BlockResultsAPIResponse
}

export default class Block extends Saga {
  private readonly height: string
  private readonly isMobile: boolean
  constructor(height: string, isMobile: boolean) {
    super()
    this.isMobile = isMobile
    this.height = height
  }

  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [
      call([this, this.fetchBlockTransaction], this.height),
      call([this, this.fetchBlockEvents], this.height),
      call([this, this.fetchBlockInfo], this.height),
      spawn([this, this.watchSetNetwork]),
    ]
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *fetchBlockTransaction(height: string): any {
    yield runSagaTask(TaskNames.Block.Transaction, function* () {
      const sdk = yield* waitforSDK()
      const chainQueryClient = sdk.query.chain
      const txs = (yield call([chainQueryClient, chainQueryClient.searchTx], `tx.height=${height}`)) as IndexedTx[]

      yield put(setTransactions(txs))
    });
  }

  private *fetchBlockInfo(height: string): any {
    yield runSagaTask(TaskNames.Block.Info, function* () {
      const sdk = yield* waitforSDK()
      const blockResponse = (yield call([sdk.tmClient, sdk.tmClient.block], parseInt(height))) as BlockResponse
      yield put(setBlockInfo({
        id: GenericUtils.toTxHash(blockResponse.blockId.hash)!,
        height: blockResponse.block.header.height,
        time: blockResponse.block.header.time.toString(),
        txs: blockResponse.block.txs,
        proposerAddress: GenericUtils.toTxHash(blockResponse.block.header.proposerAddress)!.toLowerCase(),
      }))
    })
  }

  private *fetchBlockEvents(height: string): any {
    yield runSagaTask(TaskNames.Block.Events, function* () {
      const sdk = yield* waitforSDK()
      const blockEvents = (yield getBlockResult(height, sdk.network)) as BlockResultsAPIResponse //using API instead of grpc because comsjs in sdk is outdated
      const beginBlockEvents = blockEvents.finalize_block_events.filter((o: any) => !!(o.attributes.find((attr: any) => attr.value === 'BeginBlock')))
      const endBlockEvents = blockEvents.finalize_block_events.filter((o: any) => !!(o.attributes.find((attr: any) => attr.value === 'EndBlock')))
      const blockEventFinal = {
        beginBlockEvents,
        endBlockEvents,
        height: Number(height),
        validatorUpdates: blockEvents?.validator_updates ?? [],
        consensusUpdates: blockEvents?.consensus_param_updates ?? [],
        results: blockEvents?.txs_results ?? []
      } as BlockResultsResponse
      yield put(setEvents(blockEventFinal))
    });
  }
}
