import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlProps,
  Input,
  ListItemText,
  ListSubheader,
  makeStyles,
  MenuItem,
  Select,
  Theme
} from '@material-ui/core'
import { CarbonTx } from 'carbon-js-sdk'
import clsx from 'clsx'
import { TxTypeLabel } from 'js/components/ParsedLabel'
import { switcheo } from 'js/theme/palettes/colors'
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react'

export interface FilterOption {
  label?: string
  value: string
  selected?: boolean
}

type Props = FormControlProps & {
  options: FilterOption[]
  filters?: { [index: string]: boolean }
  onFilter?: (types: string[]) => void
}

const TransactionFilter: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const {
    title,
    className,
    children,
    options,
    filters,
    onFilter,
    ...rest
  } = props
  const classes = useStyles(props)
  const [selectedTypes, setSelectedTypes] = useState<string[]>([])
  const msgTypes = Object.entries(CarbonTx.Types);
  useEffect(() => {
    if (filters) {
      setSelectedTypes(Object.keys(filters))
    }
  }, [filters])

  const onChange = (evt: ChangeEvent<any>, child: React.ReactNode) => {
    setSelectedTypes([...evt.target.value])
  }
  const onConfirmFilter = () => {
    if (typeof onFilter === 'function') {
      onFilter(selectedTypes)
    }
  }

  const renderValue = (input: any) => {
    const selectedTypes = input as string[]
    if (!selectedTypes.length) return 'All Transaction Types'
    return `Types Selected: ${selectedTypes.length}`
  }

  const humanizeCategory = (cat: string) => {
    switch (cat) {
      case 'Liquiditypool':
        return 'LiquidityPool'
      case 'Ccm':
        return 'CCM'
      case 'Subaccount':
        return 'SubAccount'
      case 'Headersync':
        return 'HeaderSync'
      case 'Cdp':
        return 'CDP'
      default:
        return cat
    }
  }

  const categorizedOptions: any = {}
  for (const { value } of options) {
    const split = value.split(".")
    const mainCategory = split[0]
    switch (mainCategory) {
      case '/Switcheo':
        let category = split[2][0]?.toUpperCase() + split[2]?.slice(1)?.toLowerCase();
        category = humanizeCategory(category)
        if (!categorizedOptions[category]) categorizedOptions[category] = []
        categorizedOptions[category].push(value)
        break
      case '/cosmos':
        let categoryCosmos = split[1][0]?.toUpperCase() + split[1]?.slice(1)?.toLowerCase();
        categoryCosmos = humanizeCategory(categoryCosmos)
        if (!categorizedOptions[categoryCosmos]) categorizedOptions[categoryCosmos] = []
        categorizedOptions[categoryCosmos].push(value)
        break
      case '/ibc':
        let categoryIbc = split[2][0]?.toUpperCase() + split[2]?.slice(1)?.toLowerCase();
        categoryIbc = humanizeCategory(categoryIbc)
        if (!categorizedOptions[categoryIbc]) categorizedOptions[categoryIbc] = []
        categorizedOptions[categoryIbc].push(value)
        break
      case '/ethermint':
        let categoryEthermint = split[1][0]?.toUpperCase() + split[1]?.slice(1)?.toLowerCase();
        categoryEthermint = humanizeCategory(categoryEthermint)
        if (!categorizedOptions[categoryEthermint]) categorizedOptions[categoryEthermint] = []
        categorizedOptions[categoryEthermint].push(value)
        break 
    }
  }

  const allCategories = Object.keys(categorizedOptions)

  return (
    <FormControl {...rest} className={clsx(classes.root, className)}>
      <Select
        multiple
        displayEmpty
        variant="filled"
        value={selectedTypes}
        onChange={onChange}
        onClose={onConfirmFilter}
        input={<Input />}
        MenuProps={{ getContentAnchorEl: null, className: classes.popOver }}
        className={classes.selectInput}
        renderValue={renderValue}
      >
        {
          allCategories.map((cat) => {
            return (
              !!(categorizedOptions[cat].length) ? categorizedOptions[cat].map((option: string, index: number) => {
                const typeUrl = option
                const fallbackMsgNam = option?.match?.(/[a-z]+$/i)?.[0];
                const msgName = msgTypes.find((entry) => typeUrl === entry[1])?.[0] ?? fallbackMsgNam;
                const formattedMsgName = msgName?.replace(/^Msg/, "").replace(/([A-Z])/g, " $1")
                const children = []
                if (index === 0) {
                  children.push(
                    <ListSubheader className={classes.subheader}>{cat}</ListSubheader>
                  )
                }
                children.push(
                  <MenuItem
                    className={classes.menuItem}
                    key={index + option}
                    value={option}
                  >
                    <Checkbox
                      color="primary"
                      checked={selectedTypes.includes(option)}
                    />
                    <ListItemText
                      primary={
                        <TxTypeLabel>{formattedMsgName}</TxTypeLabel>
                      }
                    />
                  </MenuItem>
                )
                return children
              }) :
                <div>
                  <ListSubheader className={classes.subheader}>{cat}</ListSubheader>
                  <MenuItem disabled className={classes.loadingItem}>
                    <CircularProgress size='1rem' color='secondary' />
                  </MenuItem>
                </div>
            )
          })
        }
      </Select>
    </FormControl>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: '14rem',
    maxWidth: '40rem',
    '& .MuiInputBase-input': {
      border: `none`,
    },
  },
  subheader: {
    backgroundColor: switcheo.background.grey,
    fontSize: '0.9rem',
    lineHeight: '0.875rem',
    letterSpacing: '-0.0125rem',
    color: theme.palette.secondary.main,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
    fontWeight: 'bold',
  },
  loadingItem: {
    justifyContent: 'center',
  },
  popOver: {
    '& .MuiMenu-paper': {
      backgroundColor: switcheo.background.grey,
      borderRadius: 8,
    },
  },
  selectInput: {
    '& .MuiSvgIcon-root.MuiSelect-icon': {
      color: theme.palette.text.primary,
    },
  },
  menuItem: {
    backgroundColor: switcheo.background.grey,
    padding: theme.spacing(0.75, 1.5),
    '&:hover': {
      backgroundColor: switcheo.hover,
    },
    '& .MuiCheckbox-root': {
      padding: 0,
      paddingRight: theme.spacing(1),
      color: theme.palette.secondary.main,
    },

    '& .MuiListItemText-root': {
      fontSize: '1rem',
      lineHeight: '1.25rem',
      letterSpacing: '-0.0125rem',
      color: theme.palette.primary.contrastText,
    },
  },
}))

export default TransactionFilter
