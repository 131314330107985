import { Box, BoxProps, IconButton, InputAdornment, InputBase, List, ListItem, ListSubheader } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { CarbonSDK } from 'carbon-js-sdk'
import clsx from 'clsx'
import { useRedux } from 'js/hooks'
import { switcheo } from 'js/theme/palettes/colors'
import { isValidAddress, isValidator, isValidBlock } from 'js/utils/string'
import React, { ReactElement } from 'react'
import { useHistory } from 'react-router'
import { SearchIcon } from '../assets'

interface Props extends BoxProps {
  network: CarbonSDK.Network
}

const SearchInput: React.FunctionComponent<Props> = ({
  network,
  className,
}: Props): ReactElement<Props> => {
  const history = useHistory()
  const sdk = useRedux((state) => state.core.carbonSDK)
  const [input, setInput] = React.useState('')
  const updateInput = (event: React.ChangeEvent<HTMLInputElement>) => setInput(event.target.value)
  const [showSearchResults, setShowSearchResults] = React.useState(false)
  const valAddrMap = useRedux((state) => state.core.valAddrMap)
  const validatorsList = React.useMemo(() => {
    const filteredValidators = Object.values(valAddrMap).filter((valpair) =>
      valpair.carbonValidator.description?.moniker
        .toLowerCase()
        .startsWith(input.toLowerCase()),
    )
    return filteredValidators.map(
      (valpair) => {
        const goToValidator = () => {
          history.push(`/validator/${valpair.carbonValidator.operatorAddress}`)
          setInput('')
        }
        return (
          <ListItem key={valpair.carbonValidator.operatorAddress} onClick={goToValidator}>
            {valpair.carbonValidator.description?.moniker}
          </ListItem>
        )
      },
    )
  }, [valAddrMap, input, history])

  const tokens = useRedux((state) => state.app.tokensMap)
  const tokensList = React.useMemo(() => {
    const filteredTokens = Object.values(tokens).filter((token) => {
      const name = token.name.toLowerCase()
      const denom = token.denom.toLowerCase()
      const inputText = input.toLowerCase()
      return name.startsWith(inputText) || denom.startsWith(inputText)
    })
    return filteredTokens.map(
      (token) => {
        const goToToken = () => {
          history.push(`/token/${encodeURIComponent(token.denom)}`)
          setInput('')
        }
        return (
          <ListItem key={`${token.denom}${token.tokenAddress}`} onClick={goToToken}>
            {token.name}
          </ListItem>
        )
      },
    )
  }, [tokens, input, history])

  const markets = useRedux((state) => state.app.markets)
  const marketsList = React.useMemo(() => {
    const filteredMarkets = Object.values(markets).filter(
      (market) => market.displayName.toLowerCase().startsWith(input.toLowerCase()),
    )
    return filteredMarkets.map(
      (market) => {
        const goToMarket = () => {
          history.push(`/market/${encodeURIComponent(market.id)}`)
          setInput('')
        }
        return (
          <ListItem key={market.displayName} onClick={goToMarket}>
            {market.displayName}
          </ListItem>
        )
      },
    )
  }, [markets, input, history])

  const options = [{
    subheader: 'Validators',
    list: validatorsList,
  }, {
    subheader: 'Tokens',
    list: tokensList,
  }, {
    subheader: 'Markets',
    list: marketsList,
  },
  ]

  const dropdownList = input !== ''
    && (validatorsList.length !== 0
      || tokensList.length !== 0
      || marketsList.length !== 0)
    && (
      <List>
        {options.map(({ subheader, list }) => list.length !== 0 &&
          <React.Fragment key={subheader}>
            <ListSubheader>{subheader}</ListSubheader>
            {list}
          </React.Fragment>,
        )}
      </List>
    )

  const isValidUsername = async (username: string) => {
    if (!sdk) return false

    const result = await sdk.insights.UserProfile({ username })
    const profile = result.result.entries
    if (profile)
      return profile.address
    return false
  }

  const triggerSearch = async (searchInput: string) => {
    if (!searchInput || !searchInput.length) return
    searchInput = searchInput.trim()
    // check if an input is a block
    const usernameAddress = await isValidUsername(searchInput)
    if (isValidBlock(searchInput)) {
      history.push(`/block/${searchInput}`)
    } else if (
      isValidAddress(searchInput, network) ||
      usernameAddress
    ) {
      history.push(`/account/${usernameAddress || searchInput}`)
    } else if (isValidator(searchInput, network)) {
      history.push(`/validator/${searchInput}`)
    } else if (searchInput.length === 64) {
      history.push(`/transaction/${searchInput}`)
    }
  }

  const detectEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      triggerSearch(input)
      setInput('') // clear search input
    }
  }

  const dropdownRef = React.useRef<HTMLDivElement | null>(null)
  const handleClickOutside = React.useCallback((event: any) => {
    if (dropdownRef.current === null) return
    if (dropdownRef.current.contains(event.target)) {
      setShowSearchResults(true)
    } else {
      setShowSearchResults(false)
    }
  }, [dropdownRef])

  // click away listener
  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside)
  }, [handleClickOutside])

  const classes = useStyles()
  const handleSearch = () => triggerSearch(input)
  return (
    <Box className={clsx(classes.search, className)}>
      <div ref={dropdownRef} className={classes.searchWrapper}>
        <InputBase
          placeholder="Search by Address / TxHash / Block / Token / Validator"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          fullWidth
          id="search-input"
          inputProps={{ 'aria-label': 'search' }}
          onKeyDown={detectEnterKeyPress}
          value={input}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton onClick={handleSearch} className={classes.iconBtn}>
                <SearchIcon className={classes.searchIcon} />
              </IconButton>
            </InputAdornment>
          )}
          onChange={updateInput}
        />
      </div>
      <div className={clsx(classes.searchMenu, { showSearchResults })}>
        {dropdownList}
      </div>
    </Box>
  )
}

const useStyles: any = makeStyles((theme: Theme) => ({
  iconBtn: {
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: 4,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  search: {
    position: 'relative',
    minWidth: '32.5rem',
    [theme.breakpoints.down('sm')]: {
      minWidth: 'unset',
    },
  },
  searchIcon: {
    width: '1.25rem',
    maxHeight: '1.25rem',
    '& path': {
      fill: theme.palette.text.disabled,
      [theme.breakpoints.down('sm')]: {
        fill: theme.palette.primary.contrastText,
      },
    },
  },
  searchWrapper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    borderRadius: 4,
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'transparent',
      minHeight: '2.5rem',
      width: '100%',
    },
  },
  searchMenu: {
    backgroundColor: theme.palette.secondary.main,
    maxHeight: '50vh',
    overflow: 'auto',
    display: 'none',
    '& .MuiList-root': {
      zIndex: theme.zIndex.modal + 2000,
    },
    '&.showSearchResults': {
      display: 'block',
      position: 'absolute',
      left: 0,
      right: 0,
      zIndex: theme.zIndex.modal + 2000,
      [theme.breakpoints.down('sm')]: {
        top: '3.4rem',
      },
    },
    '& .MuiListSubheader-root': {
      color: theme.palette.primary.main,
      fontSize: '0.75rem',
      lineHeight: '0.875rem',
      letterSpacing: '-0.0125rem',
      padding: theme.spacing(1.5, 2),
      fontWeight: 'bold',
      position: 'unset',
    },
    '& .MuiListItem-root': {
      color: theme.palette.common.white,
      cursor: 'pointer',
      fontSize: '0.875rem',
      lineHeight: '1.125rem',
      letterSpacing: '-0.0125rem',
      padding: theme.spacing(1.5, 2),
      '&:hover': {
        backgroundColor: switcheo.primary[800],
      },
    },
  },
  inputRoot: {
    color: 'inherit',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0.25, 0),
    },
  },
  inputInput: {
    padding: theme.spacing(1.25, 1.5),
    ...theme.typography.body1,
    color: theme.palette.text.disabled,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
  },
}))

export default SearchInput
