import { Box, makeStyles, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import { AddressUtils } from 'carbon-js-sdk'
import { TokenPair } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/erc20/erc20'
import { Transaction } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/misc/transaction'
import { Network } from 'carbon-js-sdk/lib/constant'
import { CellLink, DataLoadSegment, ListTable, Section, TableEmptyState } from 'js/components'
import PaginationByData from 'js/components/PaginationByData'
import { shortenHash } from 'js/helpers/utils'
import { useAsyncTask } from 'js/hooks'
import { RootState } from 'js/store'
import { bnOrZero } from 'js/utils'
import Long from 'long'
import moment from 'moment'
import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

export interface CCTProps {
  sectionClass?: string
  address?: string
}

interface Props extends CCTProps { }

const EVMTransfers: React.FunctionComponent<Props> = ({
  sectionClass,
  address
}: Props): ReactElement<Props> => {
  const sdk = useSelector((state: RootState) => state.core.carbonSDK)
  const classes = useStyles()
  // For pagination
  const [getEVMTransfers] = useAsyncTask('getEVMTransfers')
  const [getEVMHexAddress] = useAsyncTask('getEVMHexAddress')
  const [getERC20Tokens] = useAsyncTask('getERC20Tokens')
  const [evmHexAddress, setEvmHexAddress] = useState<string | undefined>(undefined)
  const [evmTransfers, setEvmTransfers] = useState<Transaction[]>([])
  const [erc20Mapping, setERC20Mapping] = useState<TokenPair[]>([])
  const [page, setPage] = useState(1)
  const itemsPerPage = 10
  const evmTransfersToDisplay = evmTransfers.slice((page - 1) * itemsPerPage, ((page - 1) * itemsPerPage) + itemsPerPage)

  useEffect(() => {
    if (!sdk) return
    if (!address) return

    getERC20Tokens(async () => {
      const erc20Mapping = await sdk.query.erc20.TokenPairs({})
      setERC20Mapping(erc20Mapping.tokenPairs)
    })

    getEVMHexAddress(async () => {
      const evmHexAddressRaw = await sdk.query.evmmerge.MappedEvmAddress({ address: address ?? '' })
      setEvmHexAddress(evmHexAddressRaw.mappedAddress)
    })
    getEVMTransfers(async () => {
      const transfers = await sdk.query.misc.TransactionAll({
        msgTypeFilters: ['/Switcheo.carbon.erc20.MsgConvertCoin', '/Switcheo.carbon.erc20.MsgConvertERC20'],
        address: address ?? '',
        pagination: {
          limit: new Long(10000),
          key: new Uint8Array(),
          countTotal: false,
          reverse: false,
          offset: new Long(0),
        },
        hash: '',
      })
      console.log(transfers)
      setEvmTransfers(transfers.transactions)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk, address])

  return (
    <Section
      className={sectionClass}
      title="Carbon EVM Transfers"
    >
      <DataLoadSegment loading={false}>
        <ListTable>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Hash</TableCell>
              <TableCell>From</TableCell>
              <TableCell>To</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Token</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.root}>{evmTransfersToDisplay.map((transfers, index) => renderTransfer(transfers, evmHexAddress))}</TableBody>
        </ListTable>
        {evmTransfers.length > 0 && (
          <Box marginTop={3}>
            <PaginationByData
              data={evmTransfers}
              setPage={setPage}
              page={page}
              itemsPerPage={itemsPerPage}
            />
          </Box>
        )}
        {(!evmTransfers || evmTransfers.length <= 0) && (
          <TableEmptyState itemName="crosschain transfers" />
        )}
      </DataLoadSegment>
    </Section>
  )

  function renderTransfer(t: Transaction, evmHexAddress: string | undefined): ReactNode {
    const { messages, hash, code, blockHeight, blockCreatedAt } = t
    const msgParsed = JSON.parse(messages[0].message)
    const { coin = {}, reciever, sender, amount: amountToIBC, contract_address } = msgParsed
    const { denom, amount: amountToEVM } = coin
    const messageType = messages[0].messageType?.messageType
    const isSendingToEVM = messageType === '/Switcheo.carbon.erc20.MsgConvertCoin'
    const isSenderYou = address === sender && isSendingToEVM

    const displayMessageType = isSendingToEVM ? 'Convert to ERC20' : 'Convert to ICS20'
    const displayDenom = isSendingToEVM ? denom : erc20Mapping.find((o) => o.erc20Address === contract_address)?.denom
    const displaySymbol = sdk?.token.getSymbol(displayDenom)
    const amountBN = sdk?.token.toHuman(displayDenom, bnOrZero(isSendingToEVM ? amountToEVM : amountToIBC))

    const addressBytes = AddressUtils.SWTHAddress.getAddressBytes(evmHexAddress ?? '', sdk?.network ?? Network.MainNet)
    const addressHex = Buffer.from(addressBytes).toString('hex')
    const displayReciever = isSendingToEVM ? "0x" + addressHex : reciever
    const displaySender = isSendingToEVM ? sender : "0x" + addressHex
    return (
      <TableRow key={hash} hover>
        <TableCell>
          <div className={''}>
            <div>
              <CellLink to={`/block/${blockHeight?.toNumber()}`}>
                {blockHeight?.toNumber()}
              </CellLink>
            </div>
            <div>
              <Typography style={{ color: 'rgb(140 142 143)', fontSize: '0.78em' }}>
                {moment(blockCreatedAt).format('YYYY-MM-DD HH:mm:ss')}
              </Typography>
            </div>
          </div>
        </TableCell>
        <TableCell align="left">
          <CellLink to={`/transaction/${hash}`}>
            {shortenHash(hash, 4)}
          </CellLink>
        </TableCell>
        <TableCell>
          {
            isSendingToEVM ?
              <CellLink to={`/account/${displaySender}`} style={isSenderYou ? { color: 'rgb(140 142 143)' } : {}}>
                {isSenderYou ? 'You' : shortenHash(displaySender, 8)}
              </CellLink>
              :
              <CellLink href={`https://evm-scan.carbon.network/address/${displaySender}`} style={isSenderYou ? { color: 'rgb(140 142 143)' } : {}}>
                {isSenderYou ? 'You' : shortenHash(displaySender, 8)}
              </CellLink>
          }
        </TableCell>
        <TableCell>
          {
            isSendingToEVM ?
              <CellLink href={`https://evm-scan.carbon.network/address/${displayReciever}`} style={isSenderYou ? {} : { color: 'rgb(140 142 143)' }}>
                {isSenderYou ? shortenHash(displayReciever, 8) : 'You'}
              </CellLink>
              :
              <CellLink to={`/account/${displayReciever}`} style={isSenderYou ? {} : { color: 'rgb(140 142 143)' }}>
                {isSenderYou ? shortenHash(displayReciever, 8) : 'You'}
              </CellLink>
          }

        </TableCell>
        <TableCell>
          {displayMessageType}
        </TableCell>
        <TableCell>
          <div>
            <div>
              <span style={{ marginRight: '0.2em' }}>{amountBN?.toString(10)?.toLocaleString()}</span>
              <CellLink to={`/token/${encodeURIComponent(displayDenom)}`}>
                <Typography variant={"body2"} component="span" className={classes.symbol}>
                  {displaySymbol}
                </Typography>
              </CellLink>
            </div>
          </div>
        </TableCell>
        <TableCell>
          <div>
            {code === 0 ? 'Successful' : 'Failed'}
          </div>
        </TableCell>
      </TableRow>
    )
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
  },
  symbol: {
    fontWeight: 700
  },
  paddingRight: {
    paddingRight: theme.spacing(1.5)
  },
  hashContent: {
    display: 'inline-flex',
    paddingRight: theme.spacing(1),
  },
  paddingLeft: {
    paddingLeft: theme.spacing(1),
    '&.verticalCenter': {
      placeSelf: 'center'
    }
  },
  verticalAlign: {
    verticalAlign: 'sub',
  },
  fixedWidth: {
    width: '6rem',
  },
  displayBlock: {
    display: 'block',
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  subText: {
    fontSize: '0.8rem',
    fontWeight: 'normal',
  },
  blockChainImg: {
    width: '1rem',
    height: '1rem',
  },
  iconButton: {
    width: '1rem',
    height: '1rem',
  },
  bridgingBlockchainImg: {
    width: '1.25rem',
    height: '1.25rem',
  },
  blockchainRow: {
    display: 'flex',
    alignItems: 'center',
    width: '6.1rem',
  },
  blockchain: {
    paddingLeft: theme.spacing(1),
    display: 'inline-block',
    verticalAlign: 'top',
  },
  alignRight: {
    textAlign: 'right',
  },
  amountCellHeader: {
    paddingRight: '0.75rem',
  },
  filter: {
    marginBottom: theme.spacing(2),
  },
}))

export default EVMTransfers
