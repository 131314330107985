import { Box, Breadcrumbs, CircularProgress, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import { NoSearchResultsSvg } from 'assets'
import BigNumber from 'bignumber.js'
import { Params } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/cdp/params'
import { RateStrategyParams } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/cdp/rate_strategy_params'
import { SWTHAddress } from 'carbon-js-sdk/lib/util/address'
import { BN_ZERO, bnOrZero } from 'carbon-js-sdk/lib/util/number'
import clsx from 'clsx'
import CryptoJS from 'crypto-js'
import { CellLink, ChartContainer } from 'js/components'
import Page from 'js/components/Page'
import { TaskName } from 'js/constants'
import { truncateStr } from 'js/helpers'
import { useAsyncTask, useRedux } from 'js/hooks'
import { SHIFT_DECIMALS } from 'js/utils'
import React, { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import AssetDetailHeroCard from './components/AssetDetailHeroCard'
import AssetInfoHeroCard from './components/AssetInfoHeroCard'
import InterestRateModelChart from './components/InterestRateModelChart'
import { InfoCards, parseRateStrategy } from './helper'

interface Props { }

interface CDPParamsWithStablecoinInterest extends Params {
  stablecoinInterestRate: string | number | BigNumber
}

const CDPToken: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const classes = useStyles()
  let { symbol: urlDenom } = useParams();
  urlDenom = decodeURIComponent(urlDenom)
  const sdk = useRedux((state) => state.core.carbonSDK)
  const tokens = useRedux((state) => state.app.tokensMap)
  const [loadCdpAssets, cdpAssetsLoading] = useAsyncTask(TaskName.LoadCdpAsset)
  const [allAssetsInfo, setAssetsInfo] = useState<any>({});
  const [cdpModBalance, setcdpModBalances] = useState<string | number | undefined>(undefined);
  const [loadCdpModBalances, cdpModBalancesLoading] = useAsyncTask(TaskName.LoadCdpModBalances)
  const [cdpTotalCollateral, setCdpTotalCollateral] = useState<number>(0);
  // const [loadCdpTotalCollateral] = useAsyncTask(TaskName.LoadCdpModBalances)
  const [cdpLockedToSupplyRatio, setCdpLockedToSupplyRatio] = useState<BigNumber | undefined>(undefined)
  const [loadCdpTotalMint, totalMintLoading] = useAsyncTask(TaskName.LoadCdpTotalMint)
  const [cdpTotalMint, setCdpTotalMint] = useState<number>(0)
  const [loadRateStrategies, rateStrategiesLoading] = useAsyncTask(TaskName.LoadRateStrategies)
  const [allRateStrategies, setAllRateStrategies] = useState<RateStrategyParams[]>([]);
  const [loadCdpParams, cdpParamsLoading] = useAsyncTask(TaskName.LoadCdpParams)
  const [loading, setLoading] = useState<boolean>(true)
  const [cdpParams, setCdpParams] = useState<CDPParamsWithStablecoinInterest>({
    interestFee: '',
    liquidationFee: '',
    stablecoinInterestRate: '',
    completeLiquidationThreshold: '',
    minimumCloseFactor: '',
    smallLiquidationSize: '',
    stablecoinMintCap: '',
    cdpPaused: false,
    stalePriceGracePeriod: '' as any,
    stablecoinInterestRateAdjusterCoefficient: '',
  });

  const isLoading = cdpAssetsLoading || cdpModBalancesLoading || totalMintLoading || rateStrategiesLoading || cdpParamsLoading || loading
  useEffect(() => {
    if (!sdk) return
    loadRateStrategies(async () => {
      const strategies = await sdk.query.cdp.RateStrategyAll({})
      setAllRateStrategies(strategies.rateStrategyParamsAll)
    })
    loadCdpParams(async () => {
      const params = await (await sdk.query.cdp.Params({})).params as CDPParamsWithStablecoinInterest
      setCdpParams(params ?? {
        interestFee: '',
        liquidationFee: '',
        stablecoinInterestRate: '',
        completeLiquidationThreshold: '',
        minimumCloseFactor: '',
        smallLiquidationSize: '',
        stablecoinMintCap: '',
        cdpPaused: false,
        stalePriceGracePeriod: '',
        stablecoinInterestRateAdjusterCoefficient: '',
      })
    })
    loadCdpModBalances(async () => {
      //get address 
      const cdpAddressHash = CryptoJS.SHA256(`cdp`).toString(CryptoJS.enc.Hex);
      const cdpAddress = SWTHAddress.encode(cdpAddressHash, { network: sdk.network });
      const cdpCollateralAddressHash = CryptoJS.SHA256(`collateral_pool`).toString(CryptoJS.enc.Hex);
      const cdpCollateralAddress = SWTHAddress.encode(cdpCollateralAddressHash, { network: sdk.network });
      const balance = await sdk.query.bank.Balance({ address: cdpAddress, denom: `${urlDenom}` })
      const cdpBalance = await sdk.query.bank.Balance({ address: cdpCollateralAddress, denom: `cibt/${urlDenom}` })
      const cdpTokenTotalSupply = await sdk.query.bank.SupplyOf({ denom: `cibt/${urlDenom}` })
      const cdpLockedToSupplyRatio = bnOrZero(cdpBalance.balance?.amount).dividedBy(bnOrZero(cdpTokenTotalSupply.amount?.amount))
      setCdpTotalCollateral(bnOrZero(cdpBalance.balance?.amount).toNumber())
      setcdpModBalances(balance.balance?.amount ?? 0)
      setCdpLockedToSupplyRatio(cdpLockedToSupplyRatio.isNaN() ? BN_ZERO : cdpLockedToSupplyRatio)
    })
    loadCdpTotalMint(async () => {
      const totalMint = await sdk.query.bank.SupplyOf({ denom: urlDenom })
      setCdpTotalMint(bnOrZero(totalMint.amount?.amount).toNumber())
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk])

  useEffect(() => {
    if (!tokens || !sdk || !allRateStrategies.length || !cdpParams.interestFee || cdpModBalance === undefined || !cdpLockedToSupplyRatio) return
    loadCdpAssets(async () => {
      setLoading(true)
      const asset = await sdk.query.cdp.Asset({ denom: urlDenom })
      if (!asset?.assetParams) return
      const { denom, oracleId, rateStrategyName, loanToValue, liquidationThreshold, liquidationDiscount, supplyCap, borrowCap } = asset.assetParams
      const debtInfo = await sdk.query.cdp.TokenDebt({ denom: `${urlDenom}` })
      if (!debtInfo?.debtInfo) return
      const { totalPrincipal: totalBorrowed, utilizationRate } = debtInfo.debtInfo
      const totalAmount = bnOrZero(totalBorrowed).plus(bnOrZero(cdpModBalance)).toNumber()
      const tokenInfo = tokens[denom] ?? Object.values(tokens).find((tk) => tk.denom === denom)
      let availableBorrow = Number(borrowCap ?? 0) > 0
        ? BigNumber.min(bnOrZero(borrowCap).minus(bnOrZero(totalBorrowed)), bnOrZero(cdpModBalance)).shiftedBy(-(tokenInfo?.decimals.toNumber()))
        : bnOrZero(cdpModBalance).shiftedBy(-(tokenInfo?.decimals.toNumber()))
      let notionValue = sdk.token.getUSDValue(urlDenom)
      if (!notionValue || notionValue.eq(0)) {
        // await sdk.token.reloadUSDValues()
        notionValue = bnOrZero((await sdk.query.pricing.TokenPrice({ denom })).tokenPrice?.twap).shiftedBy(-(SHIFT_DECIMALS ?? 0))
      }
      const collateralToActualBN = bnOrZero(cdpModBalance).plus(bnOrZero(totalBorrowed)).multipliedBy(cdpLockedToSupplyRatio).shiftedBy(-(tokenInfo?.decimals.toNumber()))
      const collateralToActualUSD = collateralToActualBN.multipliedBy(bnOrZero(notionValue)).toFixed(2)
      let formattedAsset: any = {
        denom,
        oracleId,
        rateStrategyName,
        loanToValue,
        liquidationThreshold: bnOrZero(liquidationThreshold).shiftedBy(-2).toNumber(),
        liquidationDiscount: bnOrZero(liquidationDiscount).shiftedBy(-2).toNumber(),
        supplyCap: bnOrZero(supplyCap).shiftedBy(-tokenInfo?.decimals.toNumber() ?? 0).toNumber(),
        borrowCap: bnOrZero(borrowCap).shiftedBy(-tokenInfo?.decimals.toNumber() ?? 0).toNumber(),
        supplyCapUSD: bnOrZero(supplyCap).shiftedBy(-tokenInfo?.decimals.toNumber() ?? 0).multipliedBy(bnOrZero(notionValue)).toNumber(),
        borrowCapUSD: bnOrZero(borrowCap).shiftedBy(-tokenInfo?.decimals.toNumber() ?? 0).multipliedBy(bnOrZero(notionValue)).toNumber(),
        totalBorrowed: bnOrZero(totalBorrowed).shiftedBy(-tokenInfo?.decimals.toNumber() ?? 0).toNumber(),
        totalBorrowedUSD: bnOrZero(totalBorrowed).shiftedBy(-tokenInfo?.decimals.toNumber() ?? 0).multipliedBy(bnOrZero(notionValue)).toNumber(),
        totalAmount: bnOrZero(totalAmount).shiftedBy(-tokenInfo?.decimals.toNumber() ?? 0).toNumber(),
        utilizationRate: bnOrZero(utilizationRate).shiftedBy(-SHIFT_DECIMALS + 4).toNumber(),
        tokenAddress: tokenInfo?.tokenAddress ?? '',
        decimals: tokenInfo?.decimals.toNumber() ?? 0,
        name: tokenInfo?.name ?? '',
        symbol: tokenInfo?.symbol ?? urlDenom,
        availableBorrow,
        availableBorrowUSD: availableBorrow.multipliedBy(bnOrZero(notionValue)),
        totalAmountUSD: bnOrZero(totalAmount).shiftedBy(-tokenInfo?.decimals.toNumber() ?? 0).multipliedBy(bnOrZero(notionValue)),
        collaterals: bnOrZero(cdpTotalCollateral).shiftedBy(-tokenInfo?.decimals.toNumber() ?? 0),
        collateralToActualUSD,
        collateralToActual: collateralToActualBN?.toNumber() ?? 0,
        mints: bnOrZero(cdpTotalMint).shiftedBy(-tokenInfo?.decimals.toNumber() ?? 0),
      }
      // calculate interest
      const rateStrategy = allRateStrategies.find((r) => r.name === formattedAsset.rateStrategyName)
      const borrowApr = await sdk.cdp.calculateAPY(urlDenom, debtInfo.debtInfo, asset.assetParams, rateStrategy) as BigNumber
      const lendApr = await sdk.cdp.calculateLendAPY(urlDenom, borrowApr, debtInfo.debtInfo, cdpParams)
      formattedAsset.borrowApr = borrowApr.shiftedBy(2).toFixed(2)
      formattedAsset.lendApr = lendApr.shiftedBy(2).toFixed(2)
      setAssetsInfo(formattedAsset)
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokens, sdk, allRateStrategies, cdpParams.interestFee, cdpModBalance, cdpTotalCollateral, cdpLockedToSupplyRatio])

  const rateStrategy = allRateStrategies.find((r) => r.name === allAssetsInfo?.rateStrategyName)
  const { optimalUsage } = parseRateStrategy(rateStrategy)
  //construct card
  const infoCards: InfoCards[] = [
    {
      title: 'Lending Info',
      items: [
        {
          field: 'APR',
          value: `${allAssetsInfo?.lendApr ?? "-"}%`,
          bold: true
        },
        {
          field: 'Total Supplied',
          value: `${bnOrZero(allAssetsInfo?.totalAmount).toFixed(3) ?? "-"} ${allAssetsInfo?.symbol?.toUpperCase() ?? "-"} ($${Number(allAssetsInfo?.totalAmountUSD?.toFixed(2) ?? 0)?.toLocaleString() ?? '-'})`
        },
        {
          field: 'Total Collateralized',
          value: `${bnOrZero(allAssetsInfo?.collateralToActual ?? 0).toFixed(3)} ${allAssetsInfo?.symbol?.toUpperCase() ?? "-"} ($${Number(allAssetsInfo?.collateralToActualUSD ?? 0)?.toLocaleString() ?? '-'})`
        },
        {
          field: 'Supply Cap',
          value: allAssetsInfo?.supplyCap
            ? `${bnOrZero(allAssetsInfo?.supplyCap)} ${allAssetsInfo?.symbol?.toUpperCase() ?? ""} ($${Number(Number(allAssetsInfo?.supplyCapUSD ?? 0).toFixed(2))?.toLocaleString() ?? '-'})`
            : '∞'
        },
      ]
    },
    {
      title: 'Borrowing Info',
      items: [
        {
          field: 'Interest',
          value: `${allAssetsInfo?.borrowApr ?? "-"}%`,
          bold: true
        },
        {
          field: 'Loan to Value',
          tooltip: "",
          value: `${bnOrZero(allAssetsInfo?.loanToValue).shiftedBy(-2) ?? "-"}%`
        },
        {
          field: 'Total Borrowed',
          value: `${bnOrZero(allAssetsInfo?.totalBorrowed).toFixed(3) ?? 0} ${allAssetsInfo?.symbol?.toUpperCase() ?? ""} ($${Number(Number(allAssetsInfo?.totalBorrowedUSD ?? 0).toFixed(2))?.toLocaleString() ?? '-'})`
        },
        {
          field: 'Borrow Cap',
          value: allAssetsInfo?.borrowCap
            ? `${bnOrZero(allAssetsInfo?.borrowCap)} ${allAssetsInfo?.symbol?.toUpperCase() ?? ""} ($${Number(Number(allAssetsInfo?.borrowCapUSD ?? 0).toFixed(2))?.toLocaleString() ?? '-'})`
            : '∞'
        },
      ],
    },
    {
      title: 'Fees & Liquidation Info',
      items: [
        {
          field: 'Interest fee',
          value: `${bnOrZero(cdpParams?.interestFee).shiftedBy(-2).toNumber() ?? "-"}%`
        },
        {
          field: 'Liquidation Discount',
          value: `${allAssetsInfo?.liquidationDiscount ?? "-"}%`
        },
        {
          field: 'Liquidation Discount Fee',
          value: `${bnOrZero(cdpParams?.liquidationFee).shiftedBy(-2).toNumber() ?? 0}%`
        },
        {
          field: 'Liquidation Threshold',
          tooltip: "",
          value: `${allAssetsInfo?.liquidationThreshold ?? "-"}%`
        },
      ]
    },
  ]

  // TODO: Change usc to the stablecoin denom
  if (urlDenom.includes('usc')) {
    infoCards[1].items2 = [
      {
        field: 'Interest',
        value: `${bnOrZero(cdpParams?.stablecoinInterestRate).shiftedBy(-2).toNumber() ?? "-"}%`,
        bold: true
      },
      {
        field: 'Liquidation Threshold',
        tooltip: "",
        value: `${allAssetsInfo?.liquidationThreshold ?? "-"}%`
      },
      {
        field: 'Total Minted',
        value: `${bnOrZero(allAssetsInfo?.mints).toFixed(3) ?? 0} ${allAssetsInfo?.symbol?.toUpperCase() ?? ""} ($${Number(Number(allAssetsInfo?.mints ?? 0).toFixed(2))?.toLocaleString() ?? '-'})`
      },
    ]
    infoCards[1].title2 = 'Mint Info'
  }
  const doesAssetExists = !!Object.keys(allAssetsInfo).length
  const cibtTokenInfo = Object.values(tokens).find((tk) => tk.denom === `cibt/${allAssetsInfo.denom}`) ?? allAssetsInfo
  return (
    <>
      <Breadcrumbs className={classes.breadcrumb}>
        <CellLink to={`/cdp`}>Borrowable Assets</CellLink>
        <Typography>{urlDenom?.length > 16 ? truncateStr(urlDenom.toUpperCase(), 5, 4, "...") : urlDenom.toUpperCase()}</Typography>
      </Breadcrumbs>
      <Page title="Borrowable Asset Details">
        {
          !doesAssetExists && !isLoading ?
            <div className={classes.emptyState}>
              <NoSearchResultsSvg className={clsx(classes.graphic)} />
              <Typography variant={"h2"} className={classes.emptyStateText}>
                Asset not found
                {/* <Typography variant={"h3"} className={classes.emptyStateText}>
                    {`Be the first to supply $${urlDenom?.toUpperCase()} on`} <CellLink href={'https://app.dem.exchange/nitron'}>Demex</CellLink>
                  </Typography> */}
              </Typography>
            </div>
            : isLoading ?
              <div className={classes.loading}><CircularProgress /></div>
              :
              <Grid container spacing={2}>
                <Grid item container spacing={2}>
                  <Grid item xs={12} sm={6} lg={3}>
                    <AssetDetailHeroCard mainDisplayURL={encodeURIComponent(`/token/${cibtTokenInfo?.denom}`)} title={"CIBT Asset"} mainDisplay={`${cibtTokenInfo?.symbol}`} subDisplay={`${cibtTokenInfo.name.length < 32 ? cibtTokenInfo.name : truncateStr(cibtTokenInfo.name, 16, 16)}`} />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <AssetDetailHeroCard title={"Total Supplied"} mainDisplay={"$" + Number(allAssetsInfo?.totalAmountUSD?.toFixed(2) ?? 0)?.toLocaleString() ?? "-"} subDisplay={<div>{Number(allAssetsInfo?.totalAmount ?? 0)?.toLocaleString() ?? "-"} <CellLink to={encodeURIComponent(`/token/${allAssetsInfo.denom}`)}>{allAssetsInfo?.symbol?.toUpperCase()}</CellLink></div>} />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <AssetDetailHeroCard title={"Available to Borrow"} mainDisplay={`$${Number(allAssetsInfo?.availableBorrowUSD?.toFixed(2) ?? 0).toLocaleString()}`} subDisplay={<div>{(allAssetsInfo?.availableBorrow?.toNumber() ?? 0).toLocaleString()} <CellLink to={encodeURIComponent(`/token/${allAssetsInfo.denom}`)}>{allAssetsInfo?.symbol?.toUpperCase()}</CellLink></div>} />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <AssetDetailHeroCard title={"Utilization Rate"} mainDisplay={bnOrZero(allAssetsInfo?.utilizationRate).shiftedBy(-2).toFixed(2) + "%"} subDisplay={`Target Utilization Rate: ${bnOrZero(optimalUsage).shiftedBy(-2)}%`} />
                  </Grid>
                </Grid>
                <Grid item container spacing={2}>
                  {infoCards.map((o, index) => {
                    return <Grid item xs={12} sm={infoCards.length - 1 === index ? 12 : 6} md={4} lg={4} key={o.title}>
                      <AssetInfoHeroCard title={o.title} items={o.items} title2={o.title2} items2={o.items2} />
                    </Grid>
                  })}
                </Grid>
                <Grid item className={classes.chartContainer}>
                  {
                    !!Object.keys(allRateStrategies).length && !!Object.keys(allAssetsInfo).length && (
                      <ChartContainer
                        title={
                          <Box className={classes.chartTitleBox}>
                            <Typography variant={"h3"} component={"span"}>
                              {`Interest Rate Model`}
                            </Typography>
                          </Box>
                        }
                        date={false}
                      >
                        <InterestRateModelChart
                          allAssetsInfo={allAssetsInfo}
                          cdpParams={cdpParams}
                          allRateStrategies={allRateStrategies}
                        />
                      </ChartContainer>
                    )
                  }
                </Grid>
              </Grid>
        }
      </Page>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  loading: {
    display: 'flex',
    placeItems: 'center',
    placeContent: 'center',
    minHeight: '7rem',
  },
  chartContainer: {
    marginTop: '-20px',
    maxWidth: '98.8%',
    '@media (max-width: 565px)': {
      maxWidth: '95.8%',
    },
  },
  graphic: {
    margin: theme.spacing(0, 0, -1),
    maxWidth: '19rem',
    maxHeight: '16.475rem',
    width: '100%',
  },
  emptyState: {
    margin: theme.spacing(6, 0, 6, 0),
    textAlign: "center",
  },
  emptyStateText: {
    textAlign: "center",
    // marginBottom: theme.spacing(2),
    padding: '0em 2em 1em 2em',
    '@media (max-width: 565px)': {
      padding: '0em 1em 1em 1em',
    },

  },
  cellClass: {
    padding: '0.5rem 1.15rem 0.5rem 0.7rem',
    whiteSpace: 'nowrap',
  },
  breadcrumb: {
    margin: theme.spacing(1, 0, 2, 0),
    color: theme.palette.text.primary,
  },
  chartTitleBox: {
    paddingBottom: theme.spacing(2),
  },
}))

export default CDPToken
